<template>
  <div>
    <b-card no-body>
      <b-card-header class="p-0">
        <b-button
          size="sm"
          variant="flat-secondary"
          class="btn-icon rounded-circle"
          style="padding: 0.5rem 10px !important"
          :disabled="isNotSelectedRows"
          @click="submitDeleteMultiEmployees"
          v-b-tooltip.hover.v-danger
          title="حذف متعدد"
        >
          <unicon name="trash-alt" width="18"></unicon>
        </b-button>
      </b-card-header>
      <b-card-body class="px-0">
        <vue-good-table
          ref="employees-table"
          :columns="columns"
          :rows="ActiveEmployeeList"
          :rtl="false"
          :small="true"
          styleClass="vgt-table condensed"
          @on-selected-rows-change="selectionChanged"
          :select-options="{
            enabled: true,
            disableSelectInfo: true,
            selectOnCheckboxOnly: true,
          }"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'action'">
              <router-link :to="'/admin/main/employees/details/' + props.row.id">
                <b-button
                  size="sm"
                  variant="flat-secondary"
                  class="btn-icon rounded-circle"
                  style="padding: 2px 6px !important; margin: auto"
                >
                  <unicon name="ellipsis-v" width="18"></unicon>
                </b-button>
              </router-link>
            </span>
            <span v-else-if="props.column.field == 'dob'">
              {{
                new Date(props.formattedRow[props.column.field]).toLocaleDateString(
                  "en-GB"
                )
              }}
            </span>
            <span v-else-if="props.column.field == 'fullName'">
              {{ props.row.firstName }} {{ props.row.lastName }}
            </span>
            <span v-else-if="props.column.field == 'userImage'">
              <img
                class="rounded-circle"
                :src="
                 !props.row.imageDto.base64
              ? !!props.row.imageDto.path
                ? props.row.imageDto.path.replace('~', $store.getters['app/domainHost'])
                : '/images/website/employee.svg'
              : props.row.imageDto.base64
                "
                 
                width="50"
                height="50"
              />
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </b-card-body>
    </b-card>

    <b-col cols="12" class="d-flex justify-content-center">
      <b-pagination
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        class="mt-1 mb-0"
        :value="EmployeeFirstPage"
        :total-rows="FilterdEmployeeList.length"
        :per-page="EmployeePageLength"
        @input="(value) => updateEmployeePagination(value)"
      >
        <template #prev-text>
          <unicon width="20" name="angle-right" fill="#A41F02" />
        </template>
        <template #next-text>
          <unicon width="20" name="angle-left" fill="#A41F02" />
        </template>
      </b-pagination>
    </b-col>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { VueGoodTable } from "vue-good-table";
import { VBTooltip } from "bootstrap-vue";
import Swal from "sweetalert2";
export default {
  components: {
    VueGoodTable,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      isNotSelectedRows: true,
      columns: [
        {
          label: "صورة المستخدم",
          field: "userImage",
        },
        {
          label: "اسم المستخدم",
          field: "fullName",
        },
        {
          label: "المنصب الوظيفي",
          field: "position",
        },
        {
          label: "رقم الجوال",
          field: "mobileNumber",
        },
        {
          label: "تاريخ الإشتراك",
          field: "dob",
        },
        {
          label: "details",
          field: "action",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      FilterdEmployeeList: (state) => state.employee.FilterdEmployeeList,
      ActiveEmployeeList: (state) => state.employee.ActiveEmployeeList,
      EmployeeFirstPage: (state) => state.employee.EmployeeFirstPage,
      EmployeePageLength: (state) => state.employee.EmployeePageLength,
    }),
  },
  created() {
    this.getEmployeeList();
  },
  methods: {
    selectionChanged() {
      this.isNotSelectedRows = !this.$refs["employees-table"].selectedRows.length;
    },
    getSelectedRow() {
      let rowIds = this.$refs["employees-table"].selectedRows.map((row) => row.id);
      return rowIds;
    },
    ...mapActions([
      "getEmployeeList",
      "updateEmployeePagination",
      "deleteMultiEmployees",
    ]),
    submitDeleteMultiEmployees() {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: true,
      });
      swalWithBootstrapButtons
        .fire({
          title: "هل أنت متأكد؟",
          text: "هل فعلاً تريد حذف الموظفين المحددين ؟",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "نعم!",
          cancelButtonText: "إلغاء",
          reverseButtons: false,
        })
        .then((result) => {
          if (result.value) {
            let selectedIds = this.getSelectedRow();
            this.deleteMultiEmployees(selectedIds);
          }
        });
    },
  },
};
</script>
